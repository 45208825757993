import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import HeaderWithIcon from './primitives/HeaderWithIcon';
import { type Destination } from '../types';

interface DestinationCardHeaderProps {
  destinations: Destination[];
  activeDestination: Destination;
  setActiveDestination: (destination: Destination) => void;
}

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // do not ever wrap text and have overflow scroll horizontally
  whiteSpace: 'nowrap',
  overflowX: 'scroll',
  // hide scrollbar
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  // add some padding to the left and right
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const DestinationCardHeader: React.FC<DestinationCardHeaderProps> = ({
  destinations,
  activeDestination,
  setActiveDestination,
}) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const typographyRefs: Array<React.RefObject<HTMLDivElement>> =
    destinations.map(() => React.createRef<HTMLDivElement>());

  useEffect(() => {
    const activeIndex: number = destinations.indexOf(activeDestination);
    if (typographyRefs[activeIndex].current != null && boxRef.current != null) {
      const selectedElement = typographyRefs[activeIndex].current;
      if (selectedElement != null) {
        boxRef.current.scrollLeft = selectedElement.offsetLeft;
      }
    }
  }, [activeDestination, typographyRefs]);

  return (
    <TitleWrapper ref={boxRef}>
      {destinations.map((destination, i) => (
        <HeaderWithIcon
          active={destination.id === activeDestination.id}
          end={i === destinations.length - 1}
          onClick={() => {
            setActiveDestination(destination);
          }}
          key={i}
          ref={typographyRefs[i]}
        >
          {destination.name}
        </HeaderWithIcon>
      ))}
    </TitleWrapper>
  );
};

export default DestinationCardHeader;
