import {
  addDoc,
  collection,
  type CollectionReference,
  deleteDoc,
  doc,
  type DocumentReference,
  getDoc,
  getDocs,
  query,
  type QueryConstraint,
  updateDoc,
} from 'firebase/firestore';
import { db, storage } from './firebase';
import {
  getDownloadURL,
  ref,
  uploadBytes,
  type UploadResult,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

class DatabaseService<Type> {
  private readonly _collectionRef: CollectionReference;
  public serviceName: string;

  constructor(collectionPath: string) {
    this._collectionRef = collection(db, collectionPath);
    this.serviceName = collectionPath;
  }

  getOne = async (id: string): Promise<Type | null> => {
    const queryDoc = doc(this._collectionRef, id);
    const snapshot = await getDoc(queryDoc);
    if (!snapshot.exists()) {
      return null;
    }
    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as Type;
  };

  getResolvedReference = async (
    documentReference: DocumentReference,
  ): Promise<Type | null> => {
    const snapshot = await getDoc(documentReference);
    if (!snapshot.exists()) {
      return null;
    }
    return {
      id: snapshot.id,
      ...snapshot.data(),
    } as Type;
  };

  getResolvedImage = async (id: string): Promise<string | null> => {
    return await getDownloadURL(ref(storage, id));
  };

  getMany = async (...clauses: QueryConstraint[]): Promise<Type[]> => {
    const q = query(this._collectionRef, ...clauses);
    const snapshot = await getDocs(q);
    const ret = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as Type;
    });
    return ret;
  };

  update = async (id: string, data: Omit<Type, 'id'>): Promise<void> => {
    await updateDoc(doc(this._collectionRef, id), data as any);
  };

  create = async (data: Omit<Type, 'id'>): Promise<DocumentReference> => {
    return await addDoc(this._collectionRef, data as any);
  };

  createImg = async (img: Blob): Promise<string> => {
    const imgRef = ref(storage, uuidv4());
    return await uploadBytes(imgRef, img).then(
      (result: UploadResult) => result.ref.fullPath,
    );
  };

  remove = async (id: string): Promise<void> => {
    deleteDoc(doc(this._collectionRef, id)).catch((error) => {
      console.error('Error removing document: ', error);
    });
  };
}

export default DatabaseService;
