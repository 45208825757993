import React from 'react';
import InputBase from '@mui/material/InputBase';

import { CloseRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface AutocompleteProps extends google.maps.places.AutocompleteOptions {
  value?: string;
  callback?: (placeId: google.maps.places.PlaceResult) => void;
}

// FIXME style autocomplete dropdown at link below
// https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
const Autocomplete: React.FC<AutocompleteProps> = ({
  value,
  callback,
  ...options
}) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] =
    React.useState<google.maps.places.Autocomplete>();

  React.useEffect(() => {
    if (ref.current != null && autocomplete == null) {
      setAutocomplete(
        new google.maps.places.Autocomplete(ref.current, {
          ...options,
        }),
      );
    }
  }, [ref, autocomplete]);

  React.useEffect(() => {
    if (autocomplete != null) {
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place != null && callback != null) {
          callback(place);
        }
      });
    }
  }, [autocomplete, callback]);

  return (
    <InputBase
      placeholder={value ?? ref.current?.value ?? 'Search'}
      inputProps={{ 'aria-label': 'search' }}
      inputRef={ref}
      sx={{
        width: '100%',
        ml: 2,
        border: '1px solid #aaa',
        borderRadius: 1,
        px: 1,
        py: 1.5,
        mx: 0,
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      endAdornment={
        ref.current != null && ref.current.value !== '' ? (
          <IconButton
            sx={{
              width: '3rem',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTopRightRadius: 9,
              borderBottomRightRadius: 9,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              // bgcolor: "grey.50",
            }}
            onClick={() => {
              if (ref.current != null) ref.current.value = '';
            }}
          >
            <CloseRounded sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        ) : null
      }
    />
  );
};

export default Autocomplete;
