import type DatabaseService from './service';
import { type WithId } from './tables';

/**
 *
 * @param service a DatabaseService of the Table you want to update
 * @param newCols a key value store of column: default value you want to set
 *
 * Steps for running a migration:
 * 1. Set the SERVICE and NEWCOLS in MigrationPage.tsx
 * 2. Navigate to /migration and run the migration
 * 3. Update the Internal (tables.ts) and external (type.d.ts) types, adding the new fields
 */
export async function* addColumnMigrationGenerator(
  service: DatabaseService<WithId>,
  newCols: Record<string, any>,
): AsyncGenerator<string> {
  yield Promise.resolve('Fetching all items');
  const allItems: WithId[] = await service.getMany();
  yield Promise.resolve('All items fetched, updating');
  yield Promise.all(
    allItems.map(async (item: WithId) => {
      await service.update(item.id as string, newCols);
    }),
  )
    .then(async () => {
      alert('Finished migration successfully');
      return await Promise.resolve('Successfully migrated all columns.');
    })
    .catch(async (err) => {
      alert('Migration failed. See console for error long.');
      console.log(err);
      return await Promise.resolve(
        'Failed to migrate all columns, see console.',
      );
    });
}
