import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  DialogTitle,
  type SvgIconProps,
  type Theme,
  useTheme,
} from '@mui/material';

interface DialogTitleWithCloseProps {
  onClose: () => void;
  CloseIcon?: React.ComponentType<SvgIconProps>;
  children: React.ReactNode;
}
const DialogTitleWithClose: React.FC<DialogTitleWithCloseProps> = ({
  onClose,
  CloseIcon = CloseOutlined,
  children,
}) => {
  const theme: Theme = useTheme();

  return (
    <DialogTitle>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {children}
        <CloseIcon
          fontSize="large"
          sx={{
            m: theme.spacing(2),
            transition: 'transform 0.3s ease-in-out',
            '&:hover': { cursor: 'pointer', transform: 'scale(1.5)' },
            color: theme.palette.text.disabled,
            fontSize: theme.typography.h2.fontSize,
          }}
          onClick={onClose}
        />
      </Box>
    </DialogTitle>
  );
};

export default DialogTitleWithClose;
