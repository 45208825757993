import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import { type Destination } from '../types';
import { useEffect, useState } from 'react';

interface DestinationContentProps {
  destination: Destination;
  openUrl?: string;
  setOpenUrl: (url: string) => void;
}

const DestinationContent: React.FC<DestinationContentProps> = ({
  destination,
  openUrl,
  setOpenUrl,
}) => {
  const [photos, setPhotos] = useState<string[]>([]);

  useEffect(() => {
    if (destination != null && !Array.isArray(destination.photos)) {
      destination.photos().then(setPhotos).catch(console.log);
    }
  }, [destination]);

  return (
    <Box>
      {' '}
      <Typography variant="h5" component="div">
        {destination?.name}
      </Typography>
      {openUrl != null ? (
        <img src={openUrl} width={'100%'} />
      ) : (
        <ImageList cols={3} rowHeight={164} variant={'quilted'}>
          {photos.map((url: string, i: number) => {
            return (
              <ImageListItem
                key={i}
                onClick={() => {
                  setOpenUrl(url);
                }}
              >
                <img src={url} />
              </ImageListItem>
            );
          })}
        </ImageList>
      )}
    </Box>
  );
};

export default DestinationContent;
