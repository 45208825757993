import { type ReactElement, useState } from 'react';
import { type StoreDestinationRequest, type Destination } from '../types';
import AddDestination from './AddDestination';
import { Box } from '@mui/system';
import { Button } from '@mui/base';
import { travelService } from '../db/TravelService';

const AddJourney: React.FC = (): ReactElement => {
  const [destinations, setDestinations] = useState<
    Array<StoreDestinationRequest | null>
  >([null]);
  const [nDestinations, setNDestinations] = useState<number>(1);

  return (
    <Box>
      {Array.from({ length: nDestinations }, (_, i) => (
        <AddDestination
          key={i}
          handleDestinationSubmit={function handleNewDestination(
            storeDestination: StoreDestinationRequest,
          ): void {
            const newArray: Array<StoreDestinationRequest | null> = [
              ...destinations,
            ];

            // Update the specific element.
            newArray[i] = storeDestination;

            // Set the new array as the new state.
            setDestinations(newArray);
            console.log(destinations, 'destinations updated');
          }}
        />
      ))}
      <Button
        onClick={() => {
          console.log('clicked', nDestinations);
          setNDestinations(nDestinations + 1);
          setDestinations([...destinations, null]);
        }}
      >
        Add Destination
      </Button>
      <Button
        disabled={destinations.filter((x) => x == null).length > 0}
        onClick={() => {
          // destinations will be nonnull because of the disabled condition
          // also treat this as Destination[] even though is a StoreDestinationRequest[]
          // for use with DestinationService
          void travelService.storeTrip(destinations as Destination[]).then(
            () => {
              setNDestinations(1);
              setDestinations([null]);
            },
            (err) => {
              alert('Store failed');
              console.log(err, 'error');
            },
          );
        }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default AddJourney;
