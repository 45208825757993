import React, { useState } from 'react';
import { type Destination } from '../types';
import { Dialog, useMediaQuery, useTheme, DialogContent } from '@mui/material';
import DestinationCardHeader from './DestinationCardHeader';
import DialogTitleWithClose from './primitives/DialogTitleWithClose';
import { ArrowBackOutlined, CloseOutlined } from '@mui/icons-material';
import DestinationContent from './DestinationContent';

interface TripCardProps {
  destinations: Destination[];
  activeDestination: Destination;
  onClose: () => void;
  setActiveDestination: (destination: Destination) => void;
}

const TripCard: React.FC<TripCardProps> = ({
  destinations,
  activeDestination,
  onClose,
  setActiveDestination,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [openUrl, setOpenUrl] = useState<string>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog fullScreen={fullScreen} open={open}>
      <DialogTitleWithClose
        onClose={() => {
          if (openUrl != null) {
            setOpenUrl(undefined);
          } else {
            setOpen(false);
            onClose();
          }
        }}
        CloseIcon={openUrl != null ? ArrowBackOutlined : CloseOutlined}
      >
        <DestinationCardHeader
          destinations={destinations}
          activeDestination={activeDestination}
          setActiveDestination={setActiveDestination}
        />
      </DialogTitleWithClose>
      <DialogContent>
        <DestinationContent
          destination={activeDestination}
          openUrl={openUrl}
          setOpenUrl={setOpenUrl}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TripCard;
