import { ArrowForward, LocationOnOutlined } from '@mui/icons-material';
import {
  Box,
  type BoxProps,
  SvgIcon,
  type SvgIconProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

/**
 * Header text
 */
const Header = styled(Typography)(() => ({
  color: 'inherit',
  fontWeight: 600,
  fontSize: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
    borderRadius: 2,
  },
}));

/**
 * Header icon
 */
interface HeaderIconProps extends SvgIconProps {
  small?: boolean;
}

const HeaderIcon = styled(SvgIcon)<HeaderIconProps>(
  ({ theme, small = false }) => ({
    fontSize: small ? theme.typography.h4.fontSize : 'inherit',
  }),
);

/**
 * Header with icons
 */
interface HeaderWithIconProps extends BoxProps {
  active?: boolean;
  end?: boolean;
  onClick?: () => void;
}

const HeaderWrapper = styled(Box)<HeaderWithIconProps>(
  ({ theme, active = false }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // do not ever wrap text
    whiteSpace: 'nowrap',
    color: active ? theme.palette.primary.main : theme.palette.text.disabled,
    fontSize: theme.typography.h2.fontSize,
    // on hover make cursor a pointer
    '&:hover': {
      cursor: 'pointer',
    },
  }),
);

const HeaderWithIcon = React.forwardRef<HTMLDivElement, HeaderWithIconProps>(
  ({ active, end, onClick, children }, ref) => {
    return (
      <HeaderWrapper ref={ref} active={active} onClick={onClick}>
        <HeaderIcon>
          <LocationOnOutlined />
        </HeaderIcon>
        <Header>{children}</Header>
        {end != null && !end && (
          <HeaderIcon small>
            <ArrowForward />
          </HeaderIcon>
        )}
      </HeaderWrapper>
    );
  },
);

export default HeaderWithIcon;
