import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import FoodPage from './pages/FoodPage';
import TravelPage from './pages/TravelPage';
import AddJourneyPage from './pages/AddJourneyPage';
import MigrationPage from './pages/MigrationPage';
import { type ReactElement } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import DevPage from './pages/DevPage';

const App = (): ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/test" element={<DevPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/food" element={<FoodPage />} />
          <Route path="/travel" element={<TravelPage />} />
          <Route path="/addJourney" element={<AddJourneyPage />} />
          <Route path="/migration" element={<MigrationPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
