import MigrationCard from '../components/MigrationCard';
import DatabaseService from '../db/service';
import { type InternalDestination, TABLES } from '../db/tables';

const SERVICE: DatabaseService<InternalDestination> =
  new DatabaseService<InternalDestination>(TABLES.LOCATIONS);
const NEWCOLS: Record<string, any> = { description: '' };

const MigrationPage: React.FC = () => {
  return <MigrationCard service={SERVICE} newCols={NEWCOLS} />;
};

export default MigrationPage;
