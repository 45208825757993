import { type DocumentReference, Timestamp } from 'firebase/firestore';
import { type Trip, type Arc, type Destination } from '../types';
import DatabaseService from './service';
import { TABLES, type InternalDestination, type InternalTrip } from './tables';
import { dateToColor } from './utils';

class TravelService {
  private readonly destinationService: DatabaseService<InternalDestination>;
  private readonly tripService: DatabaseService<InternalTrip>;

  constructor() {
    this.destinationService = new DatabaseService<InternalDestination>(
      TABLES.LOCATIONS,
    );
    this.tripService = new DatabaseService<InternalTrip>(TABLES.TRIPS);
  }

  getDestinations = async (): Promise<Destination[]> => {
    const internalDestinations: InternalDestination[] =
      await this.destinationService.getMany();
    return internalDestinations.map((internalDestination) =>
      this.toDestination(internalDestination),
    );
  };

  getTrips = async (): Promise<Trip[]> => {
    const internalTrips: InternalTrip[] = await this.tripService.getMany();
    const trips: Trip[] = await Promise.all(
      internalTrips.map(async (internalTrip) => {
        const destinations: Destination[] =
          await this.toDestinations(internalTrip);
        const arcs: Arc[] = destinations
          .slice(1)
          .map((destination, index) =>
            this.toArc(destinations[index], destination),
          );
        return {
          id: internalTrip.id,
          destinations,
          arcs,
        };
      }),
    );
    return trips;
  };

  storeDestination = async (
    destination: Destination,
  ): Promise<DocumentReference> => {
    const internalDestination: InternalDestination =
      await this.toInternalDestination(destination);
    return await this.destinationService.create(internalDestination);
  };

  storeDestinations = async (
    destinations: Destination[],
  ): Promise<DocumentReference[]> => {
    // for each destination, create an internal destination and store it
    return await Promise.all(
      destinations.map(async (destination) => {
        return await this.storeDestination(destination);
      }),
    );
  };

  editLocation = async (destination: Destination): Promise<void> => {
    const internalDestination: InternalDestination =
      await this.toInternalDestination(destination);
    this.destinationService
      .update(destination.id, internalDestination)
      .catch(console.log);
  };

  editLocations = (destinations: Destination[]): void => {
    destinations.forEach((destination) => {
      this.editLocation(destination).catch(console.log);
    });
  };

  storeTrip = async (destinations: Destination[]): Promise<void> => {
    const docs: DocumentReference[] =
      await this.storeDestinations(destinations);
    this.tripService.create({ destinations: docs }).catch(console.log);
  };

  private readonly getResolvedPhotos = async (
    photos: string[],
  ): Promise<string[]> => {
    return await Promise.all(
      photos
        .map(async (photo) => {
          return await this.destinationService.getResolvedImage(photo);
        })
        .filter((photo) => photo != null) as Array<Promise<string>>,
    );
  };

  private readonly toInternalDestination = async (
    destination: Destination,
  ): Promise<InternalDestination> => {
    const startTimestamp: Timestamp = Timestamp.fromDate(destination.startDate);
    const endTimestamp: Timestamp = Timestamp.fromDate(destination.endDate);
    const photos: string[] = await Promise.all(
      (destination.photos as Blob[]).map(
        async (photo) => await this.destinationService.createImg(photo),
      ),
    );
    return {
      ...destination,
      startDate: startTimestamp,
      endDate: endTimestamp,
      // can assume this is not the supplier because that is only for lazy loading of images
      photos,
    };
  };

  private readonly toArc = (
    startDestination: Destination,
    endDestination: Destination,
  ): Arc => {
    return {
      startLat: startDestination.lat,
      startLng: startDestination.lng,
      endLat: endDestination.lat,
      endLng: endDestination.lng,
      startColor: startDestination.color,
      endColor: endDestination.color,
    };
  };

  private readonly toDestinations = async (
    internalTrip: InternalTrip,
  ): Promise<Destination[]> => {
    const internalDestinations: Array<InternalDestination | null> =
      await Promise.all(
        internalTrip.destinations.map(async (destination) => {
          return await this.destinationService.getResolvedReference(
            destination,
          );
        }),
      );
    return internalDestinations
      .filter(
        (destination): destination is InternalDestination =>
          destination != null,
      )
      .map((destination) => {
        return this.toDestination(destination);
      });
  };

  private readonly toDestination = (
    internalDestination: InternalDestination,
  ): Destination => {
    return {
      ...internalDestination,
      startDate: internalDestination.startDate.toDate(),
      endDate: internalDestination.endDate.toDate(),
      size: 5,
      color: dateToColor(internalDestination.startDate.toDate()),
      photos: async () =>
        await this.getResolvedPhotos(internalDestination.photos),
    };
  };
}

export const travelService = new TravelService();
