import { type ChangeEvent, useEffect, useState } from 'react';
import { type StoreDestinationRequest, type Destination } from '../types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from './Autocomplete';
import dayjs, { type Dayjs } from 'dayjs';

interface AddDestinationProps {
  handleDestinationSubmit: (destination: StoreDestinationRequest) => void;
}

const optionalStoreFields: Partial<StoreDestinationRequest> = {
  photos: [],
  description: '',
};

const validateDestination = (
  storeDestination: Partial<StoreDestinationRequest>,
): boolean => {
  return (
    storeDestination.startDate != null &&
    storeDestination.endDate != null &&
    storeDestination.endDate >= storeDestination.startDate &&
    storeDestination.lat != null &&
    storeDestination.lng != null &&
    storeDestination.placeid != null &&
    storeDestination.name != null
  );
};

const style = {
  width: 400,
  bgcolor: 'background.paper',
};

const AddDestination: React.FC<AddDestinationProps> = ({
  handleDestinationSubmit,
}) => {
  const [storeDestination, setStoreDestination] = useState<
    Partial<StoreDestinationRequest>
  >({});

  useEffect(() => {
    // this confirms that all required fields are filled out
    if (validateDestination(storeDestination)) {
      // this fills in any missing optional fields
      handleDestinationSubmit({
        ...optionalStoreFields,
        ...storeDestination,
      } as StoreDestinationRequest);
    }
  }, [storeDestination]);

  useEffect(() => {
    if (
      storeDestination.startDate != null &&
      (storeDestination.endDate == null ||
        storeDestination.endDate < storeDestination.startDate)
    ) {
      setStoreDestination({
        ...storeDestination,
        endDate: storeDestination.startDate,
      });
    }
  }, [storeDestination.startDate]);

  const handleInputChange = (
    fieldName: keyof Destination,
    value: any,
  ): void => {
    setStoreDestination((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files != null) {
      const fileList: Blob[] = Array.from(event.target.files).map(
        (file) => new Blob([file], { type: file.type }),
      );
      setStoreDestination((destination) => ({
        ...destination,
        photos: [...((destination.photos as Blob[]) ?? []), ...fileList],
      }));
    }
  };

  return (
    <Box sx={style}>
      {validateDestination(storeDestination) && (
        <Typography>Valid destination</Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <DatePicker
              label="Start Date"
              value={dayjs(storeDestination?.startDate)}
              onChange={(date: Dayjs | null) => {
                handleInputChange('startDate', date?.toDate());
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">to</Typography>
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={dayjs(storeDestination?.startDate)}
              onChange={(date: Dayjs | null) => {
                handleInputChange('endDate', date?.toDate());
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <Autocomplete
        callback={(p: google.maps.places.PlaceResult) => {
          handleInputChange('placeid', p.place_id);
          handleInputChange('lat', p.geometry?.location?.lat());
          handleInputChange('lng', p.geometry?.location?.lng());
          handleInputChange('name', p.name);
        }}
      />
      <div>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Upload Images
          </Button>
        </label>
        {storeDestination.photos != null && (
          <Typography>{storeDestination.photos.length}</Typography>
        )}
      </div>
    </Box>
  );
};

export default AddDestination;
