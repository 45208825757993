import { type Destination } from '../types';

const markerSvg = `<svg viewBox="-4 0 36 36" class="destinationMarker">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="black" cx="14" cy="14" r="7"></circle>
  </svg>`;

export function createMarker(
  destination: Destination,
  onClick: () => void,
): HTMLElement {
  const el = document.createElement('div');
  el.innerHTML = markerSvg;
  el.style.color = destination.color;
  el.style.width = '30px';

  // @ts-expect-error wants a number index, but this works
  el.style['pointer-events'] = 'auto';
  el.style.cursor = 'pointer';
  el.onclick = onClick;
  return el;
}
