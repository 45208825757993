import React, { useState, useEffect, type ReactElement } from 'react';
import MyGlobe from '../globe/MyGlobe';
import { type Trip, type Destination } from '../types';
import { travelService } from '../db/TravelService';
import TripCard from '../components/TripCard';

const HomePage: React.FC = (): ReactElement => {
  const [trips, setTrips] = useState<Trip[]>([]);
  const [selectedDestination, setSelectedDestination] = useState<Destination>();

  useEffect(() => {
    travelService.getTrips().then(setTrips).catch(console.log);
  }, []);

  return (
    <>
      {trips.length > 0 && (
        <MyGlobe
          onDestinationClick={(d: Destination) => {
            setSelectedDestination(d);
          }}
          locations={trips.flatMap((t: Trip) => t.destinations)}
          arcs={trips.flatMap((t: Trip) => t.arcs)}
        />
      )}
      {selectedDestination != null && (
        <TripCard
          onClose={() => {
            setSelectedDestination(undefined);
          }}
          destinations={
            trips.find((t: Trip) =>
              t.destinations.includes(selectedDestination),
            )?.destinations ?? []
          }
          activeDestination={selectedDestination}
          setActiveDestination={setSelectedDestination}
        />
      )}
    </>
  );
};

export default HomePage;
