/*
import { Color } from "three";
import { Arc, Destination } from "../types";
import { InternalDestination } from "./tables";
import { Timestamp } from "@firebase/firestore";
import d3 from "d3";
*/

// create a function that maps a Timestamp to a three.js color on a scale from pink to purple, where the first date is january 1 2019
export const dateToColor = (date: Date): string => {
  return '#3d397d';
  /*
  const start = new Date(2019, 0, 1);
  const end = new Date();
  const diff = end.getTime() - start.getTime();
  const diffDays = diff / (1000 * 3600 * 24);
  const diffDaysFromStart =
    (date.getTime() - start.getTime()) / (1000 * 3600 * 24);
  const ratio = diffDaysFromStart / diffDays;
  // create a color on a scale from #f5c0ed to #fff based on the ratio
  const color = new Color();
  const startColor = new Color(0xf5c0ed);
  const endColor = new Color(0xffffff);
  color.setRGB(
    startColor.r + ratio * (endColor.r - startColor.r),
    startColor.g + ratio * (endColor.g - startColor.g),
    startColor.b + ratio * (endColor.b - startColor.b)
  );
  return `#${color.getHexString()}`;
  */
};
