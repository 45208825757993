import { type DocumentReference, type Timestamp } from '@firebase/firestore';

export type WithId = { id: string } & any;

export enum TABLES {
  LOCATIONS = 'locations',
  TRIPS = 'trips',
}

export interface InternalDestination {
  // firebase generated id
  id: string;
  placeid: string; // not used right now
  lat: number;
  lng: number;
  startDate: Timestamp;
  endDate: Timestamp;
  description: string;
  name: string;
  // array of their IDs stored in storage
  photos: string[];
}

export interface InternalTrip {
  // firebase generated id
  id: string;
  destinations: DocumentReference[];
}
