import { Button } from '@mui/material';
import type DatabaseService from '../db/service';
import { type InternalDestination } from '../db/tables';
import { addColumnMigrationGenerator } from '../db/migration';
import { type ReactElement, useState } from 'react';

interface MigrationCardProps {
  service: DatabaseService<InternalDestination>;
  newCols: Record<string, any>;
}

const MigrationCard: React.FC<MigrationCardProps> = ({
  service,
  newCols,
}): ReactElement => {
  const [status, setStatus] = useState<string>(
    'Click the button to start the migration',
  );
  const gen = addColumnMigrationGenerator(service, newCols);

  const runGenerator = async (): Promise<void> => {
    let result = await gen.next();

    while (result.done != null && !result.done) {
      setStatus(result.value);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay for 1 second
      result = await gen.next();
    }

    setStatus('Generator is done.');
  };

  return (
    <div>
      <p>Modifying {service.serviceName}</p>
      <p>
        Adding the following columns:{' '}
        {Object.entries(newCols)
          .map(([key, value]) => `${key}:${value}`)
          .join(', ')}
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          runGenerator().catch(console.log);
        }}
      >
        Run Migration
      </Button>
      <p>{status}</p>
    </div>
  );
};

export default MigrationCard;
